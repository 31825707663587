<template>
  <div class="domain-record-list">
    <!-- Heading ---------------------------------------------------------------------------------->
    <h4>
      <span>{{ title }}<span v-if="subtitle" class="subtitle"> {{ subtitle }}</span></span>
      <b-btn
        v-if="!addingRecord"
        variant="primary"
        style="float: right;"
        @click="showAddRecord"
      >
        Add New Record
      </b-btn>
    </h4>

    <!-- Add Record ------------------------------------------------------------------------------->
    <add-domain-record
      v-show="addingRecord"
      :type="type"
      @hide="hideAddRecord"
      @cancel="hideAddRecord"
    />

    <!-- Record List ------------------------------------------------------------------------------>
    <template v-if="records.length > 0">
      <domain-record
        v-for="record in records"
        :key="record.id"
        :type="type"
        :record="record"
        :enable-spinner="true"
        :editing-nameservers="editingNameservers"
        @setEditingNameservers="$emit('setEditingNameservers', $event)"
      />
    </template>

    <!-- No Records of Type ----------------------------------------------------------------------->
    <ct-empty-view v-else class="mt-5">
      You have no records of this type.
    </ct-empty-view>
  </div>
</template>

<script>

import DomainRecord from '../DomainRecord/DomainRecord.vue'
import AddDomainRecord from '../../AddDomainRecord.vue'
import CtEmptyView from '@/components/shared/CtEmptyView.vue'

export default {
  name: 'DomainsRecordList',
  components: {
    DomainRecord,
    AddDomainRecord,
    CtEmptyView,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: true,
    },
    records: {
      type: Array,
      required: true,
    },
    editingNameservers: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      addingRecord: false,
    }
  },
  computed: {},
  methods: {
    showAddRecord() {
      this.addingRecord = true
    },
    hideAddRecord() {
      this.addingRecord = false
    },
  },
}
</script>

<style lang="scss" scoped>

.domain-record-list {
  h4 {
    padding: unset;
    margin: unset;
    height: 5.5rem;
    font-weight: 900;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .subtitle {
      font-weight: 200;
    }
  }
}

</style>
