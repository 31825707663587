<template>
  <div class="domain-record">
    <div class="record-fields">
      <validation-observer v-slot="{ errors }">
        <component
          :is="recordTypeComponentName"
          :record="localRecord"
          :disable="!editMode"
          @input="validate(errors)"
        />
      </validation-observer>
    </div>
    <div class="record-actions">
      <template v-if="!loading">
        <template v-if="!editMode">
          <div v-if="enableSpinner">
            <fa-icon v-if="showNsSpinner" size="lg" v-b-popover.hover.top="'Nameserver change is propagating'" style="color: orange" icon="spinner" spin />
            <fa-icon v-else-if="showNsCheck" size="lg" v-b-popover.hover.top="'Nameserver change complete'" style="color: green" icon="check" />
          </div>
          <fa-icon size="lg" icon="edit" :class="computedIconClass(enableEdit)" @click="runIfEnabled(enableEditMode, enableEdit)" />
          <fa-icon size="lg" icon="trash" :class="computedIconClass(enableDelete)" @click="runIfEnabled(showDomainRecordDeleteModal, enableDelete)" />
        </template>
        <template v-else>
          <b-btn
            variant="primary"
            :disabled="!valid || !dirty"
            @click="save"
          >
            Save
          </b-btn>
          <b-btn
            variant="outline-danger"
            @click="cancel"
          >
            Cancel
          </b-btn>
        </template>
      </template>
      <ct-centered-spinner v-else />
    </div>
  </div>
</template>

<script>
import { clone, camelCase } from 'lodash'
import { makeToastMixin } from '@/mixins/makeToastMixin.js'
import { mapActions, mapGetters } from 'vuex'
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner.vue'

// Domain Records
import ExternalHost from './ExternalHost.vue'
import Mx from './Mx.vue'
import Ns from './Ns.vue'
import Srv from './Srv.vue'
import Caa from './Caa.vue'
import Subdomain from './Subdomain.vue'
import Txt from './Txt.vue'
import Nameserver from './Nameserver.vue'
import GlueRecord from './GlueRecord.vue'
import { RECORD_TYPE_MAPPING } from '@/store/modules/domains.store.js'

export default {
  name: 'DomainRecord',
  components: {
    CtCenteredSpinner,
    ExternalHost,
    Mx,
    Ns,
    Srv,
    Caa,
    Subdomain,
    Txt,
    Nameserver,
    GlueRecord,
  },
  mixins: [
    makeToastMixin,
  ],
  props: {
    type: {
      type: String,
      required: true,
    },
    record: {
      type: Object,
      required: false,
    },
    editingNameservers: {
      type: Boolean,
      required: false,
    },
    enableSpinner: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      editMode: false,
      dirty: false,
      localRecord: {},
      cachedRecord: {},
      loading: false,
      showNsCheck: false,
      valid: false,
    }
  },
  computed: {
    ...mapGetters('domains', [
      'domain',
      'nameservers',
    ]),
    enableDelete() {
      return this.domain.nameservers.length >= 2 && !this.editingNameservers
    },
    enableEdit() {
      return !this.editingNameservers
    },
    showNsSpinner() {
      return this.record.type === 'nameserver' ? !this.domain.internet_nameservers?.includes(this.record.value) : false
    },
    computedIconClass() {
      return enabled => enabled ? '' : 'disabled-icon'
    },
    recordTypeComponentName() {
      return camelCase(RECORD_TYPE_MAPPING[this.type])
    },
  },
  watch: {
    record(value) {
      this.localRecord = value
    },
    editMode() {
      if (!this.loading) {
        this.setEditingNameservers(this.editMode)
      }
    },
    showNsSpinner() {
      if (this.showNsSpinner) {
        this.showNsCheck = true
      }
    },
  },
  created() {
    this.localRecord = clone(this.record)
    this.cachedRecord = clone(this.record)
    this.editMode = this.record?.temp
  },
  methods: {
    ...mapActions('domains', [
      'setSelectedDomainRecord',
      'createDnsRecord',
      'updateDnsRecord',
      'addDomainNameserver',
      'updateDomainNameserver',
      'upsertDomainGlueRecords',
      'fetchDnsRecords',
    ]),
    validate(errors) {
      this.dirty = true
      this.valid = !Object.keys(errors).some(k => errors[k].length > 0)
    },
    async save() {
      this.loading = true
      this.editMode = false

      if (this.localRecord?.temp) {
        switch (this.type) {
          case 'nameserver': await this.addNameserver(); break;
          case 'glue_record': await this.upsertGlueRecords(); break;
          default: await this.create();
        }
      } else {
        switch (this.type) {
          case 'nameserver': await this.updateNameserver(); break;
          case 'glue_record': await this.upsertGlueRecords(); break;
          default: await this.update();
        }
      }

      await this.fetchDnsRecords({ domain: this.domain })
      this.loading = false
      this.setEditingNameservers(false)
      this.$emit('success')
    },
    cancel() {
      this.editMode = this.localRecord.temp
      this.resetRecord()

      this.dirty = false
      this.$emit('cancel')
    },
    resetRecord() {
      this.localRecord = clone(this.cachedRecord)
    },
    async update() {
      const res = await this.updateDnsRecord({
        domainId: this.domain.id,
        record: this.localRecord,
      })

      if (res.data?.status === 200) {
        this.successToast('Success', 'Updated DNS record')
        this.editMode = false
      } else {
        this.errorToast('Error: Unable to update DNS record', res.error)
        this.cancel()
      }
    },
    async create() {
      const res = await this.createDnsRecord({
        domainId: this.domain.id,
        record: this.localRecord,
      })

      if (res.data?.status === 200) {
        this.successToast('Success', 'Created DNS record')
        this.editMode = false
      } else {
        this.errorToast('Error: Unable to create DNS record', res.error)
        this.cancel()
      }
    },
    async addNameserver() {
      const res = await this.addDomainNameserver({
        domainId: this.domain.id,
        nameserver: this.localRecord.value,
      })

      if (res.data.status === 200) {
        this.successToast('Success', 'Added nameserver')
        this.editMode = false
      } else {
        this.errorToast('Error', 'Unable to add nameserver')
        this.cancel()
      }
    },
    async updateNameserver() {
      const res = await this.updateDomainNameserver({
        domainId: this.domain.id,
        index: this.localRecord.key,
        nameserver: this.localRecord.value,
      })

      if (res.data.status === 200) {
        this.successToast('Success', 'Updated nameserver')
        this.editMode = false
      } else {
        this.errorToast('Error', 'Unable to update nameserver')
        this.cancel()
      }
    },
    async upsertGlueRecords() {
      const res = await this.upsertDomainGlueRecords({
        domainId: this.domain.id,
        record: this.localRecord,
      })

      if (res.data.status === 200) {
        this.successToast('Success', 'Added glue records')
        this.editMode = false
      } else {
        this.errorToast('Error', 'Unable to add glue records')
        this.cancel()
      }
    },
    enableEditMode() {
      this.cachedRecord = clone(this.record)
      this.editMode = true
    },
    setEditingNameservers(value) {
      this.$emit('setEditingNameservers', value)
    },
    showDomainRecordDeleteModal() {
      this.setSelectedDomainRecord({ record: this.localRecord })
      this.$bvModal.show('domain-record-delete-modal')
    },
    runIfEnabled(func, enabled) {
      return enabled ? func() : null
    },
  },
}
</script>

<style lang="scss" scoped>

.domain-record {
  border-top: 1px solid lightgrey;
  display: flex;
  flex-direction: row;
  .record-fields {
    flex-grow: 1;
    padding-top: .6rem;
    ::v-deep .form-control,
    ::v-deep .custom-select {
      margin-bottom: 0;
    }
    ::v-deep .row {
      margin-left: 0;
      @for $i from 1 through 12 {
        .col-#{$i} {
          display: flex;
          align-items: center;
          padding: 0 .3em;
          flex-grow: 1;
          max-width: unset;
        }
      }
      .form-group {
        flex-grow: 1;
      }
      margin-bottom: 0;
    }
    ::v-deep .domain-name {
      font-size: .85rem;
      min-width: fit-content;
      top: .4em;
    }
  }
  .record-actions {
    svg {
      cursor: pointer;
    }
    margin-left: 20px;
    min-width: 140px;
    .btn {
      &:first-of-type {
        margin-right: .5rem;
      }
      font-size: .9rem;
      font-weight: 900;
      padding-left: .8em;
      padding-right: .8em;
    }
    padding-top: 1rem;
    display: flex;
    flex-basis: 110px;
    align-items: center;
    justify-content: space-evenly;
  }
  .disabled-icon {
    color: gray;
    opacity: 0.8;
    cursor: default !important;
  }
  ::v-deep .popdown {
    position: absolute;
    min-width: 200px;
    z-index: 3000;
    top: 80px;
    opacity: .9;

    .card-body {
      padding: 0.8rem 1rem !important;
    }
  }
}

</style>
