var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "domain-management-panel" } },
    [
      _c(
        "b-tabs",
        { attrs: { pills: "" } },
        [
          _c(
            "b-tab",
            {
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [
                      _c("strong", [_vm._v("Website")]),
                      _vm.isDomainAddonServiceInGracePeriod(
                        _vm.businessWebsiteHostingService
                      )
                        ? _c("b-icon", {
                            class: `warning-icon ml-1 vac-vertical-center ${_vm.getDomainAddonServiceClass(
                              _vm.businessWebsiteHostingService
                            )}`,
                            attrs: { icon: "exclamation-triangle-fill" },
                          })
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _vm.showDomainAddonServiceTab(_vm.businessWebsiteHostingService)
                ? _c("domain-website-hosting", {
                    attrs: {
                      subtitle: _vm.getDomainHostingSubtitle(),
                      service: _vm.businessWebsiteHostingService,
                    },
                    on: {
                      "webmail-visit-interaction":
                        _vm.logWebmailVisitInteraction,
                    },
                  })
                : _c("domain-addon-service-offer", {
                    attrs: {
                      "offer-product-title":
                        _vm.businessWebsiteHostingOfferData.title,
                      "offer-content":
                        _vm.businessWebsiteHostingOfferData.content,
                      plan: _vm.getUpgradePlan("business-website-hosting"),
                      "product-type": "business-website-hosting",
                      "other-product-type": _vm.showDomainAddonServiceTab(
                        _vm.businessEmailService
                      )
                        ? null
                        : "business-email",
                      "has-active-ssl-service": _vm.showDomainAddonServiceTab(
                        _vm.domainSslService
                      ),
                    },
                    on: {
                      "domain-addon-interaction": _vm.logDomainAddonInteraction,
                    },
                  }),
            ],
            1
          ),
          _c(
            "b-tab",
            {
              attrs: { active: _vm.activeTab === "email" },
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [
                      _c("strong", [_vm._v("Emails")]),
                      _vm.isDomainAddonServiceInGracePeriod(
                        _vm.businessEmailService
                      )
                        ? _c("b-icon", {
                            class: `warning-icon ml-1 vac-vertical-center ${_vm.getDomainAddonServiceClass(
                              _vm.businessEmailService
                            )}`,
                            attrs: { icon: "exclamation-triangle-fill" },
                          })
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _vm.showDomainAddonServiceTab(_vm.businessEmailService)
                ? _c("domain-emails", {
                    attrs: { service: _vm.businessEmailService },
                    on: {
                      "webmail-visit-interaction":
                        _vm.logWebmailVisitInteraction,
                    },
                  })
                : _c("domain-addon-service-offer", {
                    attrs: {
                      "offer-product-title": _vm.businessEmailOfferData.title,
                      "offer-content": _vm.businessEmailOfferData.content,
                      plan: _vm.getUpgradePlan("business-email"),
                      "product-type": "business-email",
                      "other-product-type": _vm.showDomainAddonServiceTab(
                        _vm.businessWebsiteHostingService
                      )
                        ? null
                        : "business-website-hosting",
                      "has-active-ssl-service": _vm.showDomainAddonServiceTab(
                        _vm.domainSslService
                      ),
                    },
                    on: {
                      "domain-addon-interaction": _vm.logDomainAddonInteraction,
                    },
                  }),
            ],
            1
          ),
          _vm.isDomainAddonServiceActive(_vm.businessWebsiteHostingService) ||
          _vm.isDomainAddonServiceActive(_vm.businessEmailService)
            ? _c(
                "b-tab",
                {
                  attrs: { active: _vm.activeTab === "ssl" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _c("strong", [_vm._v("SSL Certificate")]),
                            _vm.isDomainAddonServiceInGracePeriod(
                              _vm.domainSslService
                            )
                              ? _c("b-icon", {
                                  class: `warning-icon ml-1 vac-vertical-center ${_vm.getDomainAddonServiceClass(
                                    _vm.domainSslService
                                  )}`,
                                  attrs: { icon: "exclamation-triangle-fill" },
                                })
                              : _vm._e(),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    345133307
                  ),
                },
                [
                  _vm.showDomainAddonServiceTab(_vm.domainSslService)
                    ? _c("domain-ssl-certificate", {
                        attrs: { service: _vm.domainSslService },
                      })
                    : _c("domain-addon-service-offer", {
                        attrs: {
                          "offer-product-title": _vm.domainSslOfferData.title,
                          "offer-content": _vm.domainSslOfferData.content,
                          "product-type": "domain-ssl",
                          "other-product-type":
                            _vm.getDomainSslOtherProductType(),
                        },
                        on: {
                          "domain-addon-interaction":
                            _vm.logDomainAddonInteraction,
                        },
                      }),
                ],
                1
              )
            : _vm._e(),
          !_vm.isExternal(_vm.domain)
            ? _c(
                "b-tab",
                {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "title",
                        fn: function () {
                          return [_c("strong", [_vm._v("Settings")])]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    933070332
                  ),
                },
                [
                  _c(
                    "b-tabs",
                    { attrs: { pills: "", vertical: "" } },
                    [
                      _c(
                        "b-tab",
                        {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "title",
                                fn: function () {
                                  return [
                                    _c("strong", [_vm._v("DNS Management")]),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            1007363155
                          ),
                        },
                        [
                          _c(
                            "b-tabs",
                            { attrs: { pills: "", vertical: "" } },
                            [
                              _c(
                                "b-tab",
                                {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "title",
                                        fn: function () {
                                          return [
                                            _c("strong", [
                                              _vm._v("External Hosts"),
                                            ]),
                                            _vm._v(" (A, AAAA)"),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    4172990111
                                  ),
                                },
                                [_c("domain-external-hosts")],
                                1
                              ),
                              _c(
                                "b-tab",
                                {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "title",
                                        fn: function () {
                                          return [
                                            _c("strong", [
                                              _vm._v("TXT Records"),
                                            ]),
                                            _vm._v(" (TXT)"),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    3257959906
                                  ),
                                },
                                [_c("domain-txt-records")],
                                1
                              ),
                              _c(
                                "b-tab",
                                {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "title",
                                        fn: function () {
                                          return [
                                            _c("strong", [
                                              _vm._v("Subdomains"),
                                            ]),
                                            _vm._v(" (CNAME)"),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    2843180431
                                  ),
                                },
                                [_c("domain-subdomains")],
                                1
                              ),
                              _c(
                                "b-tab",
                                {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "title",
                                        fn: function () {
                                          return [
                                            _c("strong", [
                                              _vm._v("NS Records"),
                                            ]),
                                            _vm._v(" (NS)"),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    627169986
                                  ),
                                },
                                [_c("domain-ns-records")],
                                1
                              ),
                              _c(
                                "b-tab",
                                {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "title",
                                        fn: function () {
                                          return [
                                            _c("strong", [
                                              _vm._v("SRV Records"),
                                            ]),
                                            _vm._v(" (SRV)"),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    2231656738
                                  ),
                                },
                                [_c("domain-srv-records")],
                                1
                              ),
                              _c(
                                "b-tab",
                                {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "title",
                                        fn: function () {
                                          return [
                                            _c("strong", [
                                              _vm._v("CAA Records"),
                                            ]),
                                            _vm._v(" (CAA)"),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    3318874018
                                  ),
                                },
                                [_c("domain-caa-records")],
                                1
                              ),
                              _c(
                                "b-tab",
                                {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "title",
                                        fn: function () {
                                          return [
                                            _c("strong", [
                                              _vm._v("Email Service"),
                                            ]),
                                            _vm._v(" (MX)"),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    2764571896
                                  ),
                                },
                                [_c("domain-mx-records")],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-tab",
                        {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "title",
                                fn: function () {
                                  return [_c("strong", [_vm._v("WHOIS")])]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            1696467859
                          ),
                        },
                        [
                          _c(
                            "b-tabs",
                            {
                              attrs: {
                                pills: "",
                                vertical: "",
                                variant: "default",
                              },
                            },
                            [
                              _c(
                                "b-tab",
                                {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "title",
                                        fn: function () {
                                          return [
                                            _c("strong", [
                                              _vm._v("Registrant"),
                                            ]),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    457886734
                                  ),
                                },
                                [_c("domain-whois-registrant")],
                                1
                              ),
                              _c(
                                "b-tab",
                                {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "title",
                                        fn: function () {
                                          return [
                                            _c("strong", [_vm._v("Technical")]),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    3124356554
                                  ),
                                },
                                [_c("domain-whois-technical")],
                                1
                              ),
                              _c(
                                "b-tab",
                                {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "title",
                                        fn: function () {
                                          return [
                                            _c("strong", [_vm._v("Admin")]),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    1762383510
                                  ),
                                },
                                [_c("domain-whois-admin")],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-tab",
                        {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "title",
                                fn: function () {
                                  return [_c("strong", [_vm._v("Nameservers")])]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            3488898632
                          ),
                        },
                        [
                          _c(
                            "b-tabs",
                            { attrs: { pills: "", vertical: "" } },
                            [
                              _c(
                                "b-tab",
                                {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "title",
                                        fn: function () {
                                          return [
                                            _c("strong", [
                                              _vm._v("Nameservers"),
                                            ]),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    3488898632
                                  ),
                                },
                                [_c("domain-nameservers")],
                                1
                              ),
                              _c(
                                "b-tab",
                                {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "title",
                                        fn: function () {
                                          return [
                                            _c("strong", [
                                              _vm._v("Advanced Settings"),
                                            ]),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    2117145186
                                  ),
                                },
                                [_c("domain-glue-records")],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.showAdminTools
            ? _c("b-tab", {
                attrs: { disabled: "", inactive: "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "tab-divider" }, [
                            _c("div", { staticClass: "vertical-rule" }),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  98587189
                ),
              })
            : _vm._e(),
          _vm.showAdminTools
            ? _c(
                "b-tab",
                {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "title",
                        fn: function () {
                          return [_c("strong", [_vm._v("Admin Control Panel")])]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    85414791
                  ),
                },
                [
                  _c(
                    "b-tabs",
                    { attrs: { pills: "", vertical: "" } },
                    [
                      _c(
                        "b-tab",
                        {
                          staticClass: "force-padding",
                          attrs: { active: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "title",
                                fn: function () {
                                  return [
                                    _c("strong", [
                                      _vm._v("Domain Hosting Login"),
                                    ]),
                                    _c("br"),
                                    _c("span", [_vm._v("(cPanel)")]),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            2297101648
                          ),
                        },
                        [
                          _c("domain-admin-hosting-login", {
                            attrs: { subtitle: "(cPanel)" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-tab",
                        {
                          staticClass: "force-padding",
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "title",
                                fn: function () {
                                  return [
                                    _c("strong", [_vm._v("Domain Hosting")]),
                                    _c("br"),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.getDomainHostingSubtitle())
                                      ),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            2109465209
                          ),
                        },
                        [
                          _c("domain-admin-hosting", {
                            attrs: { subtitle: _vm.getDomainHostingSubtitle() },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-tab",
                        {
                          staticClass: "force-padding",
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "title",
                                fn: function () {
                                  return [
                                    _c("strong", [_vm._v("Domain Swap")]),
                                    _c("br"),
                                    _c("span", [_vm._v("(Domains)")]),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            4229968256
                          ),
                        },
                        [
                          _c("domain-admin-swap", {
                            attrs: { subtitle: "(Domains)" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("domain-record-delete-modal"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }