<template>
  <b-container class="mt-2">
    <template v-if="loaded">
      <b-button
        variant="outline-secondary"
        class="back-button d-flex align-items-center"
        aria-label="Go back button"
        @click="goBack"
      >
        <feather-icon type="chevron-left" /> Websites
      </b-button>

      <b-row class="mt-4" align-v="center">
        <b-col>
          <masthead
            class="websites-masthead-mobile"
            :title="domain.domain_name"
            :show-search="false"
          >
            <template #title-icon>
              <a :href="'https://' + domain.domain_name"
                 :aria-label="domain.domain_name + 'link'"
                 target="_blank"
              >
                <fa-icon class="external-link-icon" icon="external-link-alt" />
              </a>
            </template>
          </masthead>
          <span class="badge badge-pill status-badge"> {{ domain.status }} </span>
        </b-col>
      </b-row>
      <domain-management-panel
        :key="domainServicesLength"
        :domain="domain"
      />
    </template>
    <ct-centered-spinner v-else />
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Masthead from '@/components/shared/Masthead'
import { makeToastMixin } from '@/mixins/makeToastMixin'
import CtCenteredSpinner from '../components/shared/CtCenteredSpinner'
import DomainManagementPanel from '../components/Domains/DomainManagementPanel'
import FeatherIcon from '../components/shared/FeatherIcon'

export default {
  name: 'Website',
  components: {
    Masthead,
    CtCenteredSpinner,
    DomainManagementPanel,
    FeatherIcon,
  },
  mixins: [
    makeToastMixin,
  ],
  data: function() {
    return {
      loaded: false,
      swappingDomain: false,
    }
  },
  computed: {
    ...mapGetters('domains', [
      'domain',
    ]),
    ...mapGetters('domainEmails', [
      'domainEmails',
    ]),
    ...mapGetters('vouchers', [
      'voucherByProductCategory',
    ]),
    ...mapGetters('companies', [
      'currentCompany',
    ]),
    domainServicesLength(){
      return this.domain ? this.domain.services.length : 0
    },

    /* temporarily disabled for clients.
    showSwapDomainButton() {
      if (this.currentCompany) {
        this.fetchCompanyVouchers(this.currentCompany.id)

        const hasBusinessDomainSwapVoucher = !!this.voucherByProductCategory('business-domain-swap')
        const businessDomainVoucherNotRedeemed = this.voucherByProductCategory('business-domain-swap')?.redeemed === false

        return hasBusinessDomainSwapVoucher && businessDomainVoucherNotRedeemed
      } else {
        return false
      }
    },
    */
  },
  async mounted() {
    await this.fetchDomain({ id: this.$route.params.id })
    await this.fetchDomainEmails({ domainId: this.$route.params.id })
    await this.fetchDomainWhois({ id: this.$route.params.id, showPrivate: !this.domain.domain_privacy })
    await this.fetchDomainSsl({ id: this.$route.params.id })
    await this.setActiveServicesCount()
    this.loaded = true
    if (this.domain && !this.domain.autopay_id && this.showToClient()) {
      const body = `Autopay is currently not set up for your domain service. Your domain is set to expire in ${this.daysLeft()} day(s)`
      this.warningToast('AutoPay', body)
    }
  },
  methods: {
    ...mapActions('domains', [
      'fetchDomain',
      'fetchDomainWhois',
      'fetchDomainSsl',
    ]),
    ...mapActions('domainEmails', [
      'fetchDomainEmails',
    ]),
    ...mapActions('account', [
      'setActiveServicesCount',
    ]),
    ...mapActions('vouchers', [
      'fetchCompanyVouchers',
    ]),
    domainUrl(item) {
      return `https://www.${item.domain_name}`
    },
    goBack() {
      this.$router.go(-1)
    },
    showToClient() {
      const daysleft = this.daysLeft()
      return (daysleft <= 5 && daysleft >= 0) || (daysleft <= 30 && daysleft > 5 && daysleft % 5 === 0)
    },
    daysLeft() {
      const today = new Date()
      const expiration = new Date(this.domain.expire_date)

      today.setHours(0, 0, 0, 0)
      expiration.setHours(0, 0, 0, 0)

      const diffTime = expiration - today
      return Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    },

    /* temporarily disabled for clients.
    showSwapDomain() {
      this.swappingDomain = true
      this.$bvModal.show('domain-swap-modal')
    },
    hideSwapDomainSuccess() {
      this.swappingDomain = false
      this.$bvModal.hide('domain-swap-modal')
      this.goBack()
    },
    hideSwapDomainCancel() {
      this.swappingDomain = false
      this.$bvModal.hide('domain-swap-modal')
    },
    */
  },
}
</script>

<style lang="scss" scoped>
::v-deep .websites-masthead-mobile {
  .row {
    margin-bottom: 0.625rem !important;
  }
  .row > div:first-child {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
    h2 {
      font-weight: 800 !important;
    }
  }
  .external-link-icon {
    width: 16px;
    height: 16px;
    vertical-align: middle;
    color: #4E4E52;
    margin-left: 0.5rem;
    margin-bottom: 1rem;
  }
}

.status-badge {
  background-color: #2E3798;
  color: #FFFFFF;
  padding: 0.25rem;
  border-radius: 5px;
  text-transform: capitalize;
  font-weight: 600;
}

.container {
  max-width: 1310px;
}

.back-button {
  gap: 0.5rem;
  padding: 0.375rem 1rem;
  border-radius: 5px;
  border: 1px solid #CECED2;
  color: #4E4E52;

  &:hover, &:active {
    color: black;
    background-color: transparent;
  }
}

.custom-pagination {
  float: center !important;
  position: relative !important;
  width: 100% !important;
}

.img-container {
  text-align: center;
}

.border-bottom {
  margin-bottom: 2em;
  border-bottom: 1px solid black;
}

.center-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 0 3.75em;
}

.max-button {
  max-width: 300px;
}

.website-page {
  width: 100%;
}

@media only screen and (max-width: 1024px) {
  .website-page {
    width: 100% !important;
  }
}

// Style all nested form components within the Website management page
::v-deep label {
  font-size: .85rem;
  margin-bottom: .3rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
::v-deep .form-control,
::v-deep .custom-select {
  height: 46px !important;
  min-height: 46px !important;
}

</style>
