var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "domain-emails" } },
    [
      _c("h4", [
        _c("span", [_vm._v("Manage Email Accounts")]),
        _c(
          "div",
          { staticClass: "float-right item-actions" },
          [
            !_vm.addingDomainEmail && !_vm.serviceIsExpiring
              ? _c(
                  "b-button",
                  {
                    attrs: {
                      variant: "primary",
                      "aria-label": "Add New Email Account button",
                    },
                    on: { click: _vm.showAddDomainEmail },
                  },
                  [_vm._v("\n        Add New Email Account\n      ")]
                )
              : _vm._e(),
            _c(
              "b-button",
              {
                attrs: {
                  variant: "outline-primary",
                  "aria-label": "Setup Email Client button",
                },
                on: {
                  click: function ($event) {
                    return _vm.openSetupEmailClient()
                  },
                },
              },
              [_vm._v("\n        Setup Email Client\n      ")]
            ),
            _vm.serviceIsExpiring
              ? _c(
                  "b-button",
                  {
                    attrs: {
                      variant: "primary",
                      "aria-label": "Reactivate Business Email button",
                    },
                    on: { click: _vm.openConfirmWindow },
                  },
                  [_vm._v("\n        Reactivate Business Email\n      ")]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm.serviceIsExpiring
        ? _c("domain-service-pending-removal", {
            attrs: { service: _vm.service },
          })
        : _vm._e(),
      _vm.serviceIsDeleting ? _c("div", [_vm._m(0)]) : _vm._e(),
      _vm.addingDomainEmail
        ? _c("add-domain-email", {
            on: { addDomainEmailClose: _vm.handleAddDomainEmailClose },
          })
        : _vm._e(),
      _vm.domainEmails.length > 0
        ? _c("ct-table", {
            attrs: {
              items: _vm.domainEmails,
              loaded: _vm.domainEmails !== null,
              fields: _vm.tableFields,
              "is-paginated": false,
              small: false,
              stacked: false,
              expandable: false,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "cell(actions)",
                  fn: function (data) {
                    return _c(
                      "div",
                      { staticClass: "record-actions" },
                      [
                        !_vm.loading.includes(data.item.id)
                          ? [
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    variant: "primary",
                                    disabled: _vm.serviceIsDeleting,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.launchWebmail(data.item)
                                    },
                                  },
                                },
                                [
                                  _c("strong", [
                                    _vm._v("\n            Webmail\n          "),
                                  ]),
                                ]
                              ),
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    variant: "outline-primary",
                                    disabled: _vm.serviceIsDeleting,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changePassword(data.item)
                                    },
                                  },
                                },
                                [
                                  _c("strong", [
                                    _vm._v(
                                      "\n            Change Password\n          "
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    variant: "outline-danger",
                                    disabled:
                                      _vm.serviceIsDeleting ||
                                      _vm.disableEmailDelete,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteEmail(data.item)
                                    },
                                  },
                                },
                                [
                                  _c("strong", [
                                    _vm._v("\n            Delete\n          "),
                                  ]),
                                ]
                              ),
                            ]
                          : _c("ct-centered-spinner"),
                      ],
                      2
                    )
                  },
                },
              ],
              null,
              false,
              3158657323
            ),
          })
        : _vm._e(),
      _vm.domainEmails.length < 1 && !_vm.addingDomainEmail
        ? _c("ct-empty-view", { staticClass: "mt-5" }, [
            _vm._v(
              "\n    You haven't added any email accounts to your domain.\n  "
            ),
          ])
        : _vm._e(),
      _c("email-delete-modal", {
        on: {
          delete: _vm.confirmEmailDeleteModal,
          hide: _vm.hideEmailDeleteModal,
        },
      }),
      _c("email-change-password-modal", {
        attrs: { email: _vm.selectedEmail },
        on: { hide: _vm.hideEmailChangePasswordModal },
      }),
      _vm.showConfirmWindow
        ? _c("reactivate-domain-service-confirm-window", {
            attrs: {
              service: _vm.service,
              "is-deleting": _vm.serviceIsDeleting,
            },
            on: {
              hideConfirmWindow: _vm.hideConfirmWindow,
              changeInProcess: _vm.changeInProcess,
            },
          })
        : _vm._e(),
      _c("setup-email-client-modal", {
        on: { hide: _vm.hideSetupEmailClient },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("i", { staticClass: "text-danger" }, [
      _vm._v(
        "\n      Due to suspension all passwords set to your email accounts have been invalidated and removed.\n      "
      ),
      _c("br"),
      _vm._v(
        "\n      Upon reactivation you will have to update the passwords to any email accounts\n      you are wanting to authenticate via username and password with.\n      "
      ),
      _c("br"),
      _vm._v(
        "\n      The Webmail button will still function as a method to login while you update your passwords.\n    "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }