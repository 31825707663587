<template>
  <div id="domain-addon-service-offer" class="pb-4">
    <div v-if="!settingUpAddonService">
      <!-- Heading ---------------------------------------------------------------------------------->
      <h4>
        {{ hasUnredeemedVoucherForProductType ? "Setup" : "Get" }} {{ offerProductTitle }}
      </h4>

      <div v-if="!hasUnredeemedVoucherForProductType" v-html="offerContent" />
      <div v-else>
        <p>
          You have an unredeemed voucher for <strong>{{ offerProductTitle }}</strong>!
        </p>
        <p>
          Click <strong>Setup Service</strong> to setup your new service!
        </p>
      </div>

      <div>
        <div v-if="phone() && !hasUnredeemedVoucherForProductType" class="mb-4">
          <p>Call us today to learn more!</p>
          <feather-icon type="phone" />
          <span v-phone-number="phone()" title="Telephone" />
        </div>

        <b-btn
          v-if="showAddServiceButton() && !loadingCheckout"
          variant="primary"
          class="d-flex align-items-end"
          aria-label="Add Service button"
          @click="setupCheckoutWithDomainAddonService()"
        >
          Add Service
        </b-btn>

        <b-btn
          v-if="showSetupServiceButton() && !settingUpAddonService"
          variant="primary"
          class="d-flex align-items-end"
          aria-label="Setup Service button"
          @click="setup()"
        >
          Setup Service
        </b-btn>

        <ct-centered-spinner v-else-if="loadingCheckout" class="loading-spinner mb-5" />

        <domain-addon-additional-offers-modal
          v-if="showAdditionalOffers || loadingCheckout"
          :domain-addon-service-type="productType"
          :domain-addon-service-product="domainAddonServiceProduct"
          :domain-addon-other-service-product="domainAddonOtherServiceProduct"
          :domain-addon-ssl-service-product="domainAddonSslServiceProduct"
          :show-ssl-offer="!hasActiveSslService"
          @continue-to-checkout="showDomainAddonCheckoutModal()"
          @hide="showDomainAddonCheckoutModal()"
        />

        <domain-addon-checkout-modal
          v-if="!!domainAddonServiceProduct || loadingCheckout"
          :domain-addon-service-type="productType"
          @setup-addon-service="setup()"
        />
      </div>
    </div>
    <div v-else>
      <ct-centered-spinner class="loading-spinner">
        <p class="loading-text">
          Setting up your new {{ getSetupServiceTitle() }} service, this can take a few minutes...
        </p>
        <p>
          <em>
            Please do not navigate away or refresh the page.
          </em>
        </p>
      </ct-centered-spinner>
    </div>
  </div>
</template>

<script>
import { makeToastMixin } from '@/mixins/makeToastMixin.js'
import { mapActions, mapGetters } from 'vuex'
import { phoneNumber } from '@/directives/directives'
import FeatherIcon from '@/components/shared/FeatherIcon.vue'
import DomainAddonCheckoutModal from '@/components/Domains/DomainAddonCheckoutModal.vue'
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner.vue'
import DomainAddonAdditionalOffersModal
  from '@/components/Domains/DomainAddonAdditionalOffersModal.vue'

export default {
  name: 'DomainAddonServiceOffer',
  components: {
    DomainAddonAdditionalOffersModal,
    CtCenteredSpinner,
    DomainAddonCheckoutModal,
    FeatherIcon,
  },
  directives: { phoneNumber },
  mixins: [ makeToastMixin ],
  props: {
    offerProductTitle: {
      type: String,
      required: true,
    },
    offerContent: {
      type: String,
      required: true,
    },
    productType: {
      type: String,
      required: true,
    },
    otherProductType: {
      type: String,
    },
    hasActiveSslService: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadingCheckout: false,
      addonProductIds: [],
      servicesAdded: [],
      settingUpAddonService: false,
      domainAddonServiceProduct: null,
      domainAddonOtherServiceProduct: null,
      domainAddonSslServiceProduct: null,
      website: this.$store.getters['website/website'],
    }
  },
  computed: {
    ...mapGetters('companies', [
      'currentCompany',
    ]),
    ...mapGetters('domains', [
      'domain',
    ]),
    ...mapGetters('checkout', [
      'context',
      'productIdInCart',
    ]),
    ...mapGetters('vouchers', [
      'unRedeemedVoucherByProductCategory',
    ]),
    hasUnredeemedVoucherForProductType() {
      return !!this.unRedeemedVoucherByProductCategory(this.productType)
    },
    showAdditionalOffers () {
      const addonProductAndSslLoaded = !!this.domainAddonServiceProduct && !!this.domainAddonSslServiceProduct

      return this.otherProductType ? addonProductAndSslLoaded && !!this.domainAddonOtherServiceProduct : addonProductAndSslLoaded
    },
  },
  mounted() {
    this.fetchDomainProduct(this.productType).then((resp) => {
      this.domainAddonServiceProduct = resp[0]
    })

    if (this.otherProductType) {
      this.fetchDomainProduct(this.otherProductType).then((resp) => {
        this.domainAddonOtherServiceProduct = resp[0]
      })
    }

    this.fetchDomainProduct("domain-ssl").then((resp) => {
      this.domainAddonSslServiceProduct = resp[0]
    })
  },
  methods: {
    ...mapActions('domains', [
      'addService',
      'fetchDomain',
      'fetchDomainSsl',
    ]),
    ...mapActions('domainEmails', [
      'createDomainEmail',
      'fetchDomainEmails',
    ]),
    ...mapActions('account', [
      'setActiveServicesCount',
    ]),
    ...mapActions('companies', [
      'setCurrentCompany',
      'loadActiveServiceByType',
    ]),
    ...mapActions('checkout', [
      'addToCart',
      'fetchProduct',
      'setupContext',
    ]),
    async fetchDomainProduct(productType) {
      return await this.fetchProduct({
        productKind: 'simple_product',
        productCategory: productType,
      })
    },
    async setupCheckoutWithDomainAddonService() {
      this.loadingCheckout = true

      //Make sure this stuff is loaded and don't reload it if it already is.
      if (!this.currentCompany) {
        await this.setCurrentCompany({ id: this.domain.company_id })
      }

      if (!this.context) {
        await this.setupContext({ company_id: this.domain.company_id })
      }

      //Make sure the product isn't already in the cart before we add it.
      if (!this.productIdInCart(this.domainAddonServiceProduct.id)) {
        await this.addToCart(this.domainAddonServiceProduct)
      }

      this.loadingCheckout = false

      if (!!this.domainAddonOtherServiceProduct || (!this.hasActiveSslService && this.productType !== "domain-ssl")) {
        this.showAdditionalOffersModal()
      } else {
        this.showDomainAddonCheckoutModal()
      }
    },
    async setup() {
      this.settingUpAddonService = true
      this.$bvModal.hide(`domain-addon-${this.productType}-checkout-modal`)

      let returnResult = true

      const result = await this.addService({
        domainId: this.domain.id,
      })

      if (result?.success) {
        const servicesAdded = this.hasUnredeemedVoucherForProductType ? [this.domainAddonServiceProduct.name] : this.servicesAdded
        await this.$emit('domain-addon-interaction', this.productType, servicesAdded)
        this.successToast('Success', 'Addon service has been setup!')

        await this.loadActiveServiceByType({
          id: this.domain.company_id,
          type: this.productType,
        })

        await this.reloadDomain()
        this.settingUpAddonService = false
        returnResult = true
      } else {
        this.settingUpAddonService = false
        returnResult = false
        this.errorToast('Error', 'Unable to setup addon service.')
      }

      return returnResult
    },
    showDomainAddonCheckoutModal() {
      this.setServicesAdded()
      this.$bvModal.show(`domain-addon-${this.productType}-checkout-modal`)
    },
    showAdditionalOffersModal() {
      this.$bvModal.show(`domain-addon-${this.productType}-additional-offers-modal`)
    },
    phone() {
      if (!this.website || !Array.isArray(this.website.phone_numbers)) {
        return null
      }

      return (this.website.phone_numbers.find((a) => a.kind === 'billing') || {}).phone_number
    },
    setServicesAdded(){
      const products = []
      const availableServices = [
        this.domainAddonServiceProduct,
        this.domainAddonOtherServiceProduct,
        this.domainAddonSslServiceProduct,
      ]

      availableServices.forEach(service => {
        if (service && this.productIdInCart(service.id))
          products.push(service.name)
      })

      this.servicesAdded = products
    },
    async reloadDomain() {
      await this.fetchDomain({ id: this.domain.id })
      await this.fetchDomainEmails({ domainId: this.domain.id })
      await this.fetchDomainSsl({ id: this.domain.id })
      await this.setActiveServicesCount()
    },
    showAddServiceButton() {
      return !!this.domainAddonServiceProduct && !this.loadingCheckout && !this.hasUnredeemedVoucherForProductType
    },
    showSetupServiceButton() {
      return this.hasUnredeemedVoucherForProductType
    },
    getSetupServiceTitle() {
      if (this.domainAddonServiceProduct) {
        return this.domainAddonServiceProduct.name
      } else {
        return this.unRedeemedVoucherByProductCategory(this.productType).product.name
      }
    },
  },
}
</script>

<style lang="scss" scoped>

#domain-addon-service-offer {
  min-height: 300px;
  padding: 0 2rem 0 2rem;
  border: 1px solid #d4d4d4;
  border-radius: 8px 0 0 8px;

  h4 {
    padding: unset;
    margin: unset;
    height: 5.5rem;
    font-weight: 900 !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .subtitle {
      font-weight: 200;
    }
  }

  .record-actions {
    margin-left: 0;
    max-width: 400px;
    width: 400px;
    display: flex;
    justify-content: flex-end !important;
  }

  ::v-deep {
    .item-actions {
      .expandable-row {
        padding-left: unset;
        display: flex;
        justify-content: flex-end;
      }
      button {
        font-weight: 900;
        margin: 2px 4px;
        padding-left: .8em;
        padding-right: .8em;
        &.btn-primary a {
          color: white;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    td {
      vertical-align: middle;
    }
  }

  .loading-spinner {
    height: 6rem !important;
  }
  .loading-text {
    width: max-content;
    word-wrap: break-word;
    max-width: 75vw;
  }
}

</style>
