<template>
  <b-modal
    id="domain-swap-modal"
    ref="domain-swap-modal"
    title="Domain Swapping"
    no-close-on-backdrop
    no-close-on-esc
    centered
    hide-header-close
    hide-footer
    size="lg"
  >
    <div v-html="modalText" />
    <hr>
    <domain-swap-configuration @cancel="$emit('cancel')" @hide="$emit('hide')" />
  </b-modal>
</template>

<script>
import DomainSwapConfiguration from '@/components/Domains/DomainSwapConfiguration.vue'
import * as DOMPurify from 'dompurify'

export default {
  name: 'DomainSwapModal',
  components: { DomainSwapConfiguration },
  props: {
    selectedDomain: null,
  },
  data() {
    return {}
  },
  computed: {
    modalText() {
      return DOMPurify.sanitize('<p><strong>You are about to swap this domain.</strong><br/>This will transfer your current domain back to us, and set your active domain to one of your choosing. This will also migrate all wordpress site content and webmail data over to your new domain.</p>' +
      '<p><em>This process is one-time only and not reversible. Please ensure you select your domain swap carefully.</em></p>')
    },
  },
}
</script>
