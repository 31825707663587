<template>
  <div>
    <domain-record-list
      id="domain-nameservers"
      title="Nameservers"
      type="nameserver"
      add-button-text="Add Nameserver"
      :records="nameservers.map((item, index) => { return { key: index, value: item, type: 'nameserver' }})"
      :editing-nameservers="editingNameservers"
      @setEditingNameservers="setEditingNameservers"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DomainRecordList from './DomainRecordList.vue'

export default {
  name: 'DomainNameservers',
  components: {
    DomainRecordList,
  },
  data() {
    return {
      editingNameservers: false,
      waiting: false,
    }
  },
  computed: {
    ...mapGetters('domains', [
      'nameservers',
      'domain',
    ]),
  },
  watch:{
    "domain.nameservers"() {
      this.refreshNameservers()
    },
  },
  created() {
    this.refreshNameservers()
  },
  methods: {
    ...mapActions('domains', [
      'fetchDomainNameservers',
    ]),
    setEditingNameservers(value) {
      this.editingNameservers = value
    },
    refreshNameservers() {
      if (!this.waiting && !this.nameserversPropagated()) {
        this.waiting = true
        setTimeout(() => {
          this.fetchNameservers()
          this.waiting = false
          this.refreshNameservers()
        }, 120000)
      }
    },
    nameserversPropagated() {
      if (!this.domain.nameservers) {
        return true
      }
      if (!this.domain.internet_nameservers) {
        return false
      }
      return this.domain.internet_nameservers.filter(item => this.domain.nameservers.includes(item)).length === 0
    },
    async fetchNameservers() {
      await this.fetchDomainNameservers({
        domain: this.domain,
      }, true)
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
